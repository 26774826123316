import React, { Component } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Box ,Link,Typography} from '@mui/material';



const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14}}>
    Privay-policy
    </Typography>,
  ];


export class Privacy extends Component {


  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#f1f4f6'}}>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
      <Box sx={{minHeight:300,mt:6}}>
<br/>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
<br/>
    <Typography variant='h1' sx={{fontSize:27,fontFamily:'sans-serif',fontWeight:'800'}}>Privacy Policy</Typography> 
<br/>
<br/>
    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>PURPOSE OF COLLECTION AND PROCESSING</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
    We  don't  use Your Personal data to provide and improve the Service in present time or transfer of data.</Typography> 



    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>
    We  don't  use Your Personal data to provide and improve the Service in present time or transfer of data.
<br/>
1] Interpretation and Definitions
<br/> 
2] Collecting and Using Your Personal Data
<br/>
3] Detailed Information on the Processing of Your Personal Data
<br/>
4] GDPR Privacy
<br/>
5] CCPA Privacy
<br/>
6] "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
Children's Privacy
<br/>
7] Your California Privacy Rights (California Business and Professions Code Section 22581)
<br/>
8] Links to Other Websites
<br/>
9] Changes to this Privacy Policy
<br/>
10] Contact Us
<br/>

what is Interpretation and Definitions
Interpretation:   
The words of which the initial letter is capitalized have meanings defined under the following conditions.The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
Definitions:
For the purposes of this Privacy Policy:
<br/><br/>
You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
<br/><br/>
Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.
<br/><br/>
Company (referred to as either "the Company", "We", "Us","individuals or "Our" in this Agreement) refers to  mtel technology  App
<br/><br/>
For the purpose of the GDPR, the Company is the Data Controller.
<br/><br/>
Application means the software program provided by the Company downloaded by You on any electronic device, named  mtel
<br/><br/>
Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
<br/><br/>
Account means a unique account created for You to access our Service or parts of our Service.
<br/><br/>
Service refers to the Application.
<br/><br/>
Country refers to country: INDIA
<br/><br/>
Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
<br/><br/>
For the purpose of the GDPR, Service Providers are considered Data Processors.
<br/><br/>
Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.
<br/><br/>
Personal Data is any information that relates to an identified or identifiable individual.
<br/><br/>
For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.
<br/><br/>
For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
<br/><br/>
Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
<br/><br/>
Data Controller, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.
<br/><br/>
Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
<br/><br/>
Business, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.
<br/><br/>
Consumer, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
<br></br>
Sale, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's Personal information to another business or a third party for monetary or other valuable consideration.

<br/><br/>
1  Collecting and Using Your Personal Data
<br/>
Types of Data Collected
<br/>
Personal Data
<br/>
While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You.General Information. When you sign up to use the App, we may collect Personal data about you such as:
<br/>
Email address
<br/>
First name and last name
<br/>
Phone number
<br/>
Gender
<br/>
Password or passcode etc.
<br/>
The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services.these login facility is not available in app but after some time login facility will be.
<br/>
Google
<br/>
Facebook
<br/>
If You decide to register through or otherwise grant Us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
<br/>
You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
<br/>
Your Consent and Our Use of Your Personal Data
<br/>
By creating a profile or registering to use our apps, you expressly agree to:
<br/>
We may process the your data you provide by using the app to import or export the data with third-party services, .
<br/>
We may process the account data you provide by using the app and create it through your account only for the purposes of login or registration, third-party services, such as Apple ID, Google account and Facebook account.
<br/>
Except as provided in this privacy policy, we will not transfer any of your personal data to third parties.
<br/>
The Company may use Personal Data for the following purposes:
<br/>
To provide and maintain our Service, including to monitor the usage of our Service.
<br/>
To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
<br/>
For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
<br/>
To contact You: To contact You by email, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
<br/>
To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
<br/>
We may share your personal information in the following situations:
<br/>
With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service.
<br/>
For examples, As your consent at the registration screen, we may import into the App Personal data about your study and books and activities from third-party services . The imported Personal data may include: books and other study material. We will handle any such third-party information in accordance with this Privacy Policy.
<br/>
Forums Cookies (Webview browser cookies) - Forum Cookies generally are used to deliver or optimize our services, to communicate with users or manage their forum accounts, to develop content, or for the purposes of research and analysis. you may refuse to accept browser cookies by activating the appropriate setting. However, if you select this setting you may be unable to access certain parts of our services.
<br/>
Third-Party Advertisers - We may use third-party advertising companies to serve ads when you visit the Application. These companies may use information about your visits to the app that are contained in cookies in order to optimize the performance of the marketing campaigns and provide ads about goods and services of interest to you. We NEVER shares any data you are tracking in the app (e.g. when you get your Personal Data) with third-Party Advertisers.
<br/>
Analytics - We may  use third party analytics tools, Google Analytics, to help Us measure traffic and usage trends for the Service. Google Analytics collects information such as device, location and app usage information. We may use the information get from Google Analytics only to customize and personalize your App experience for statistical purposes and analytics.
<br/>
For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.
<br/>
With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
<br/>
With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
<br/>
With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
<br/>
Retention of Your Personal Data
<br/>
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
<br/>
The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
<br/>
Transfer of Your Personal Data
<br/>
Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
<br/>
Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
<br/>
The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
<br/>
Disclosure of Your Personal Data
<br/>
Business Transactions
<br/>
If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
<br/>
Law enforcement
<br/>
Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
<br/>
Other legal requirements
<br/>
The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
<br/>
Comply with a legal obligation
<br/>
Protect and defend the rights or property of the Company
<br/>
Prevent or investigate possible wrongdoing in connection with the Service
<br/>
Protect the personal safety of Users of the Service or the public
<br/>
Protect against legal liability
<br/>
Security of Your Personal Data
<br/>
We use administrative, technical, and physical security measures to help protect your personal information. We use the following information security measures to protect your personal data:
<br/>
Encrypt your personal data during transport and rest;
<br/>
System vulnerability scanning and penetration testing
<br/>
Protect data integrity;
<br/>
Organizational and legal measures.
<br/>
Perform regular data protection impact assessments
<br/>
The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
<br/>
<br/>
2]  Detailed Information on the Processing of Your Personal Data 
<br/>
Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
<br/>

Analytics
<br/>
We may use third-party Service providers to monitor and analyze the use of our Service.
<br/>

•  Google Analytics
<br/>
Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
<br/>
You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy?hl=en

<br/>

For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en

<br/>

Advertising
<br/>
We may use Service providers to show advertisements to You to help support and maintain Our Service.

<br/>

•  Google AdSense & DoubleClick Cookie
<br/>
Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.
<br/>

You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: http://www.google.com/ads/preferences/

<br/>

•  AdMob by Google
<br/>

You can opt-out from the AdMob by Google service by following the instructions described by Google: https://support.google.com/ads/answer/2662922?hl=en
<br/>


For more information on how Google uses the collected information, please visit the "How Google uses data when you use our partners' sites or app" page: https://policies.google.com/technologies/partner-sites or visit the Privacy Policy of Google: https://policies.google.com/privacy

<br/>

Behavioral Remarketing
<br/>
The Company uses remarketing services to advertise on third party websites to You after You visited our Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.
<br/>
•  Google Ads (AdWords)
<br/>
Google Ads (AdWords) remarketing service is provided by Google Inc.
<br/>
You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads

Google also recommends installing the Google Analytics Opt-out Browser Add-on - https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.

For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en

<br/>

Payments
<br/>
We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).

We will not store or collect Your payment card details. 

•  Apple Store In-App Payments

Their Privacy Policy can be viewed at https://www.apple.com/legal/privacy/en-ww/

•  Google Play In-App Payments

Their Privacy Policy can be viewed at https://www.google.com/policies/privacy/

<br/>

•  PayPal

Their Privacy Policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full

•  WeChat

Their Privacy Policy can be viewed at https://www.wechat.com/en/privacy_policy.html
<br/>
<br/>
3 . GDPR Privacy
<br/>

Legal Basis for Processing Personal Data under GDPR
<br/>
We may process Personal Data under the following conditions:
<br/>
Consent: You have given Your consent for processing Personal Data for one or more specific purposes.Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.
<br/>
Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.
<br/>
Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.
<br/>
Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.
<br/>
Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.
<br/>
In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
<br/>
Your Rights under the GDPR
<br/>
The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.

You have the right under this Privacy Policy, and by law if You are within the EU, to:

Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.

Request correction of the Personal Data that We hold about You. You have the right to to have any incomplete or inaccurate information We hold about You corrected.

Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.

Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it. You can exercise your right by emailing Us or contact us

Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.

Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.

Exercising of Your GDPR Data Protection Rights

You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.

You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.
<br/>
<br/>
4 . CCPA Privacy
<br/>
Your Rights under the CCPA

Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:

The right to notice. You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.

The right to access / the right to request. The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party's direct marketing purposes.

The right to say no to the sale of Personal Data. You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit a email or stablish a contact

The right to know about Your Personal Data. You have the right to request and obtain from the Company information regarding the disclosure of the following:



The categories of Personal Data collected

The sources from which the Personal Data was collected

The business or commercial purpose for collecting or selling the Personal Data

Categories of third parties with whom We share Personal Data

The specific pieces of Personal Data we collected about You

The right to delete Personal Data. You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months. You can exercise your right by emailing Us or contact us

The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your Consumer's rights, including by:

Denying goods or services to You

Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties

Providing a different level or quality of goods or services to You

Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.

Exercising Your CCPA Data Protection Rights

In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email us or contact us



The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.



Do Not Sell My Personal Information

We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that "sells" personal information as defined by the CCPA law.



If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.



Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use.



Website

You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:



From Our "Cookie Consent" notice banner

Or from Our "CCPA Opt-out" notice banner

Or from Our "Do Not Sell My Personal Information" notice banner

Or from Our "Do Not Sell My Personal Information" link

The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.



Mobile Devices

Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:



"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices

"Limit Ad Tracking" on iOS devices

You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device.
<br/>
<br/>


 5   "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
Our Service does not respond to Do Not Track signals.
<br/>
<br/>
However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.

<br/>
<br/>

6  Children's Privacy
<br/>
<br/>
Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
<br/>
<br/>
7   Your California Privacy Rights (California Business and Professions Code Section 22581)
California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.
<br/>
To request removal of such data, and if you are a California resident, You can contact Us by emailing Us  and include the email address associated with Your account.

Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.


<br/>
<br/>

8   Links to Other Websites
<br/>
Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
<br/>
<br/>
9  Changes to this Privacy Policy
<br/>
We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.

We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.

<br/>
<br/>

  10  Contact us
We take customer questions seriously..If users has any doubts or question about policy they can contact at : mteltech@gmail.com

      </Typography> 





    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>Questions and updates
    </Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>If you have any questions or suggestions about our privacy practices, please feel free to contact us at: mteltech@gmail.com</Typography> 


<br/>
      </Box>
      </Container>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Privacy